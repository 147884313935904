// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-ultronele-src-templates-tutorial-js": () => import("./../node_modules/gatsby-theme-ultronele/src/templates/tutorial.js" /* webpackChunkName: "component---node-modules-gatsby-theme-ultronele-src-templates-tutorial-js" */),
  "component---node-modules-gatsby-theme-ultronele-src-templates-quiz-js": () => import("./../node_modules/gatsby-theme-ultronele/src/templates/quiz.js" /* webpackChunkName: "component---node-modules-gatsby-theme-ultronele-src-templates-quiz-js" */),
  "component---node-modules-gatsby-theme-ultronele-src-templates-post-js": () => import("./../node_modules/gatsby-theme-ultronele/src/templates/post.js" /* webpackChunkName: "component---node-modules-gatsby-theme-ultronele-src-templates-post-js" */),
  "component---node-modules-gatsby-theme-ultronele-src-templates-user-js": () => import("./../node_modules/gatsby-theme-ultronele/src/templates/user.js" /* webpackChunkName: "component---node-modules-gatsby-theme-ultronele-src-templates-user-js" */),
  "component---node-modules-gatsby-theme-ultronele-src-templates-category-js": () => import("./../node_modules/gatsby-theme-ultronele/src/templates/category.js" /* webpackChunkName: "component---node-modules-gatsby-theme-ultronele-src-templates-category-js" */),
  "component---node-modules-gatsby-theme-ultronele-src-pages-404-js": () => import("./../node_modules/gatsby-theme-ultronele/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-ultronele-src-pages-404-js" */),
  "component---node-modules-gatsby-theme-ultronele-src-pages-about-js": () => import("./../node_modules/gatsby-theme-ultronele/src/pages/about.js" /* webpackChunkName: "component---node-modules-gatsby-theme-ultronele-src-pages-about-js" */),
  "component---node-modules-gatsby-theme-ultronele-src-pages-index-js": () => import("./../node_modules/gatsby-theme-ultronele/src/pages/index.js" /* webpackChunkName: "component---node-modules-gatsby-theme-ultronele-src-pages-index-js" */),
  "component---node-modules-gatsby-theme-ultronele-src-pages-profile-js": () => import("./../node_modules/gatsby-theme-ultronele/src/pages/profile.js" /* webpackChunkName: "component---node-modules-gatsby-theme-ultronele-src-pages-profile-js" */)
}

