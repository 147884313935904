module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-ultronele-default","short_name":"ultronele","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"/builds/geneliu/jojoedu/node_modules/gatsby-theme-ultronele/src/images/favicon-230.png"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-ultronele/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
